import React from 'react'
import {Link} from 'react-router-dom'

export default function Navbar(props) {
    return (
        <div className="navbar-wrapper">
            <Link to="/dashboard/add-enrollments">Add Enrollments</Link>
            {/* <Link to="/dashboard/view-enrollments">View Enrollments</Link>
            <Link to="/dashboard/progress">View Progress</Link> */}
            <Link to="/dashboard/overview">Overview</Link>
        </div>
    )
}