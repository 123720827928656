import React, { Component } from 'react';
import axios from 'axios';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';

export default class Overview extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      columns: [
        {
          Header: 'Email',
          accessor: 'email'
        },
        {
          Header: 'Completion Percentage',
          accessor: 'completedPercent'
        },
        {
          Header: 'Enrolled Courses',
          accessor: 'courseCodes'
        },
        {
          Header: 'User Id',
          accessor: 'id'
        },
        {
          Header: 'Created Date',
          accessor: 'createdDate'
        }
      ]
    };
  }

  componentDidMount() {
    axios.get('/api/overview').then(response => {
      const { body } = response.data;
      const { users } = body;
      users.forEach(user => {
        let newCourseCode = '';
        for (let key in user.courseCodes) {
          newCourseCode += `${key}, `;
        }
        if (newCourseCode.length)
          newCourseCode = newCourseCode.substring(0, newCourseCode.length - 2);
        user.courseCodes = newCourseCode;
        user.completedPercent = user.completedPercent.toFixed(2) + '%';
        user.createdDate = moment(user.milestones.accountCreated).format(
          'MMM Do, YYYY'
        );
        return user;
      });
      this.setState({
        users
      });
    });
  }
  render() {
    let { users, columns } = this.state;
    return (
      <div>
        <ReactTable
          data={users}
          columns={columns}
          defaultSorted={[
            {
              id: 'createdDate',
              desc: false
            }
          ]}
        />
      </div>
    );
  }
}
