import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { Route, Switch } from 'react-router-dom';

//Components
import Login from './Views/Login'
import AddEnrollments from './Views/AddEnrollments'
import ViewEnrollments from './Views/ViewEnrollments'
import Progress from './Views/Progress'
import Overview from './Views/Overview'
import Dashboard from './Views/Dashboard'
import Navbar from './Components/Navbar'

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Login}/>
        <Route path="/dashboard" render={props => (
          <div className="dashboard-wrapper">
            <Navbar></Navbar>
            <Switch>
              <Route path="/dashboard/add-enrollments" component={AddEnrollments}/>
              <Route path="/dashboard/view-enrollments" component={ViewEnrollments}/>
              <Route path="/dashboard/progress" component={Progress}/>
              <Route path="/dashboard/overview" component={Overview}/>
              <Route component={AddEnrollments}/>
            </Switch>
          </div>
        )}/>
      </Switch>
    );
  }
}

export default App;
