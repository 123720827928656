import React, {Component} from 'react'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'

export default class Dashboard extends Component {
    constructor() {
        super()

        this.state = {
            email: '',
            code: 'pfyearup-nyc'
        }
    }

    async componentDidMount() {
        try {
           await axios.get('/api/authCheck')
        }
        catch(err) {
            this.props.history.push('/')
        }
    }

    createStudent = async (e) => {
        e.preventDefault()
        let {email, code} = this.state 
        if(!email || !code) return;
        const username = email.split('@')[0]
        let studentData = {
            username,
            password: username,
            email,
            courseCode: code,
            sectionsId: username
        }
  
        try {
            let {data} = await axios.post('/api/enrollments', studentData)
            if(!data.success) toast.error(data.message, {autoClose: 3000})
            else  {
                toast.success('Created and enrolled student and sent email invitation!', {autoClose: 4000})
                this.setState({email: ''})
            }
        }
        catch(err) {
            toast.error('Something went wrong on the server. Try again shortly.', {autoClose: 3000})
            console.log('error', err)
        }
    }

    handleChange = (value, key) => {
        let obj = {}
        obj[key] = value;
        this.setState(obj)
    }

    render() {
        let {
            email,
            code
        } = this.state

        return (
            <div className="view-container">
                <ToastContainer/>
                <form onSubmit={this.createStudent} className="nycda-form">
                    <p>Enroll Student</p>
                    <input 
                    type="email" 
                    placeholder="Student's Email" 
                    onChange={(e) => this.handleChange(e.target.value, 'email')}
                    value={email}/>
                    <input 
                    type="text"
                    placeholder="CourseCode"
                    value={code}
                    onChange={(e) => this.handleChange(e.target.value, 'code')}/>
                    <button onSubmit={this.createStudent}>Submit Student</button>
                </form>
            </div>
        )
    }
}