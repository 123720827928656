import React, {Component} from 'react'
import axios from 'axios'

export default class ViewEnrollments extends Component {
    render() {
        return (
            <div>
                ViewEnrollments!
            </div>
        )
    }
}
