import React, {Component} from 'react'
import axios from 'axios'

export default class Progress extends Component {
    constructor() {
        super()
    }

    componentDidMount() {
        axios.get('/api/progress').then( response => {
            console.log('progress response', response)
        })
    }
    render() {
        return (
            <div>
                Progress!
            </div>
        )
    }
}
