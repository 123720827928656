import React, {Component} from 'react'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'

export default class Login extends Component {
    constructor() {
        super()
        this.state = {
            username: '',
            password: ''
        }
    }

    async componentDidMount() {
        try {
            const {data} = await axios.get('/api/authCheck')
            if(data.isAdmin && data.loggedIn) this.props.history.push('/dashboard')
        }
        catch (err) {
            
        }
    }

    submitLogin = async (e) => {
        e.preventDefault()
        let {username, password} = this.state
        try {
            let {data} = await axios.post('/api/login', {username, password})
            if(data.loggedIn && data.isAdmin) this.props.history.push('/dashboard')
        }
        catch(err) {
            let {response} = err
            if(response.status === 500) toast.error('Something went wrong on the server', {autoClose: 3000})
            else toast.error('Invalid username + password combination', {autoClose: 3000})
        }
    }

    handleChange = (value, key) => {
        let obj = {}
        obj[key] = value;
        this.setState(obj)
    }

    render() {
        let {username, password} = this.state
        return (
            <div className="view-container">
                <ToastContainer/>
                <form onSubmit={this.submitLogin} className="nycda-form">
                    <p>Login</p>
                    <input 
                    type="text" 
                    placeholder="username" 
                    value={username}
                    onChange={(e) => this.handleChange(e.target.value, 'username')}
                    autoComplete="username"/>
                    <input 
                    type="password" 
                    placeholder="password" 
                    value={password}
                    onChange={(e) => this.handleChange(e.target.value, 'password')}
                    autoComplete="current-password"/>
                    <button onClick={this.submitLogin}>Login</button>
                </form>
            </div>
        )
    }
}