import React, {Component} from 'react'
import axios from 'axios'

export default class Dashboard extends Component {
    render() {
        return (
            <div>
                Dashboard!
            </div>
        )
    }
}
